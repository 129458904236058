import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { SideBar } from './features/drawer';
import { ReportHubComp } from './screens/ReportHub';
import Toolbar from '@mui/material/Toolbar';
import { Login } from './screens/Login';
import { ForgotPassword } from './screens/ForgotPassword';
import { ResetPassword } from './screens/ResetPassword';
import { useSelector } from 'react-redux';
import { LoadingProvider } from './context/loading.context';
import React, { useEffect, useState } from 'react';
import './App.css';

function App() {
  const [width, setWidth] = React.useState(0);

  const state = useSelector((s: any) => {
    return (s as any).loginReducer;
  });
  return (
    <div style={{ display: 'flex' }}>
      <LoadingProvider>
        <Router>

          {/* <SideBar setWidth={setWidth} /> */}
          <div style={{ height: '100vh', width: '100%', flexGrow: 1 }}>
            <Toolbar />
            <div style={{ height: 'calc(100% - 64px)' }}>
              <Routes>
                <Route path="*" element={<Navigate to="/" replace />}></Route>
                <Route path="/" element={<ReportHubComp />}></Route>
                <Route path="/corelife/*" element={<ReportHubComp />}></Route>
              </Routes>
            </div>
          </div>

        </Router>
      </LoadingProvider>
    </div>
  );
}

export default App;
