export function returnWidth(column: any, columns: any) {
  if (column.width) return column.width;
  return 100 / columns.length + '%';
}

export function isColumnNumeric(tableData: any, column: any) {
  // console.log(tableData, column);
  let count = 0;

  for (let i = 0; i < tableData.length; i++) {
    const el = tableData[i];
    if (!isNaN(el[column.accessor]) || el[column.accessor] == '')
      count++;
  }

  return tableData.length === count;
}