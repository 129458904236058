const CONFIG = {
  LOCAL: {
    PORTAL: "http://localhost:23334/restapi.ashx/",
    BACKEND_ENDPOINT: "http://localhost:23334/restapi.ashx",
  },
  DEV: {
    PORTAL: "https://mozzaz-portal-ui-dev.azurewebsites.net/",
    BACKEND_ENDPOINT: "https://server-dev.mozzazcare.com/restapi.ashx",
    INTAKE_FORM_PORTAL: "https://mozzaz-custom-web-portal-dev.azurewebsites.net/"
  },
  QA: {
    PORTAL: "https://mozzaz-portal-ui-qa.azurewebsites.net/",
    BACKEND_ENDPOINT: "https://server-qa.mozzazcare.com/restapi.ashx",
    INTAKE_FORM_PORTAL: "https://mozzaz-custom-web-portal-qa.azurewebsites.net/"
  },
  STAGGING: {
    PORTAL: "https://staging.mozzazcare.com/",
    BACKEND_ENDPOINT: "https://server-staging.mozzazcare.com/restapi.ashx",
    INTAKE_FORM_PORTAL: "https://mozzaz-custom-web-portal-stagng.azurewebsites.net/"
  },
  PROD: {
    PORTAL: "https://mozzazcare.com/",
    BACKEND_ENDPOINT: "https://server-prod.mozzazcare.com/restapi.ashx",
    INTAKE_FORM_PORTAL: "https://corelife-forms.mozzazcare.com/"
  },
};

export function config() {
  const ENV = process.env.REACT_APP_ENV || localStorage.getItem("Env") || "LOCAL";
  return CONFIG[ENV];
}