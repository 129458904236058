import { config } from './config';

export function BACKEND_ENDPOINT() {
  return config().BACKEND_ENDPOINT;
}

export function PORTAL() {
  return config().PORTAL;
}

export function INTAKE_FORM_PORTAL() {
  return config().INTAKE_FORM_PORTAL;
}

// 'B8B0471C-FD3E-4157-A82C-788E3CFAAE75'; //PSTool
// 'F5E3E80D-2EDC-436A-A7E7-D44F8C5F7938'; //Mozzaz-Portal
