import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { PopUp } from '../components/PopUp';
import { useState } from 'react';
import { logout } from '../services/AuthAPIService';
import { useLoading } from '../context/loading.context';
import { useNavigate } from 'react-router-dom';
import { getState, dispatch, actions } from '../store';
import { Autocomplete, Button, TextField } from '@mui/material';
import { LoginState } from '../store/slices/login/type';
import { getOMReportNames, } from '../utils/util';

const drawerWidth = 200;
const drawerClosedWidth = 64;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  boxShadow: '0px 1px 0px 0px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
  zIndex: theme.zIndex.drawer,
  marginLeft: drawerClosedWidth,
  width: `100%`,
  // width: `calc(100% - ${drawerClosedWidth}px)`,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `100%`,
    // width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const AppBarMenu = (props: any) => {
  const [open, setOpen] = React.useState(true);
  const [dropDownValue, setDropDownValue] = React.useState(props.selectedReport);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showPopup, setShowPopup] = useState(false);
  const { loading, setLoading } = useLoading();
  const navigate = useNavigate();
  const session: LoginState = getState().loginReducer;

  React.useEffect(() => {
    setOpen(props.open);
  }, []);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openSignOutPopUp = () => {
    setShowPopup(!showPopup);
  };

  const signOut = async (e: any) => {
    setLoading(true);
    const logoutData: any = await logout();
    setShowPopup(false);
    setLoading(false);
    if (logoutData.IsSuccess) {
      if (JSON.stringify(logoutData).includes('Session deleted successfully')) {
        dispatch(actions.loginActions.logout(null));
        navigate('/corelife');
      }
      return;
    }
    //SRS what to do if logout fails?
    dispatch(actions.loginActions.logout(null));
  };

  const renderBtns = () => {
    let render = [];
    if (!props.buttons) return;

    for (let i = 0; i < props.buttons.length; i++) {
      const element = props.buttons[i];
      render.push(
        <Button
          variant={element.variant}
          onClick={element.click}
          key={element.text}
          style={{
            fontFamily: 'ProximaNovaSoftSemibold',
            fontSize: 18,
            marginLeft: 10,
          }}
        >
          {element.text}
        </Button>
      );
    }

    return render;
  };

  return (
    <Box sx={{ boxShadow: '0px 2px 4px #0000001a' }}>
      <AppBar position="fixed" open={open} style={{ backgroundColor: '#FFFFFF', width: `calc(100% - ${props.width}px)` }}>
        <Toolbar style={{ justifyContent: 'space-between', alignContent: 'space-around' }}>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              color: 'secondary.main',
              fontFamily: 'proximaNovaSoftSemibold',
              fontSize: 20,
            }}
          >
            {props.title}
          </Typography>
          {props.isOMReports ? (<Autocomplete
            sx={{width: 400, "&.css-3nvvg0-MuiAutocomplete-root .MuiOutlinedInput-root": {padding: 0}}}
            disablePortal
            clearIcon={false}
            blurOnSelect
            getOptionLabel={(option: any) => option.title}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            options={getOMReportNames()}
            value={dropDownValue}
            onChange={(event, value) => {
              props.onOMReportChange(value)
              setDropDownValue(value ? value : {title: '', value: ''})
          }}
            renderInput={(params) => <TextField sx={{"& .MuiOutlinedInput-root": {padding: 0}}} {...params} />}
          />) : (<div style={{}}>{renderBtns()}</div>)}
          <Box>
            <IconButton
              aria-label="profile-menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="primary"
            >
              <ManageAccountsOutlinedIcon sx={{ fontSize: 28 }} />
            </IconButton>
            <Menu
              id="profile-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              sx={{ boxShadow: '0px 3px 6px #00000029', borderRadius: '8px' }}
            >
              {session && session.sessionData && (
                <MenuItem onClick={handleClose} sx={{ fontSize: 18, color: 'custom_grey.main', fontFamily: 'ProximaNovaMedium' }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <p style={{ margin: 0 }}>{`${session.sessionData.FirstName} ${session.sessionData.LastName}`}</p>
                    <p style={{ margin: 0, fontSize: 13 }}>{`Username: ${session.sessionData.Username}`}</p>
                  </div>
                </MenuItem>
              )}
              <MenuItem
                onClick={openSignOutPopUp}
                sx={{ fontSize: 18, color: 'primary.main', fontFamily: 'ProximaNovaSoftSemibold' }}
              >
                Sign Out
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      {showPopup && (
        <PopUp
          open={true}
          header="Sign Out"
          message="Are you sure you want to sign out?"
          primaryBtnText="Sign Out"
          secondaryBtnText="Cancel"
          primaryBtnEvent={signOut}
          secondaryBtnEvent={() => {
            setShowPopup(false);
          }}
        ></PopUp>
      )}
    </Box>
  );
};

export default React.memo(AppBarMenu);
