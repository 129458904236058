import axios from 'axios';
import { BACKEND_ENDPOINT } from '../config/Constant';
import { requestTemplate } from './RequestTemplate';
import { getState } from '../store';
import { LoginState } from '../store/slices/login/type';
import { handleUnauthorizedUser } from './AuthAPIService';
import { retFlagName } from './UtilAPI';
import { log } from '../utils/util';

export async function AccountLookupQuery() {
  const session: LoginState = getState().loginReducer;
  if (!session.isLoggedIn || !session.sessionData) {
    return;
  }

  let req = {
    API_NAME: 'AccountLookupQuery',
    API_AUTHENTICATION_TYPE: 'auth_session',
    API_AUTHENTICATION_TOKEN: session.sessionData.AuthToken,
    AUTHENTICATION_OWNER_ID: session.sessionData.CustomerId,
    API_PAYLOAD: {
      fastReportFlagName: "CL_FastReport_AccountLookup",
      customerId: session.sessionData.CustomerId,
      // regionName: ''//MD, NC, All
    },
  };
  req = { ...req, ...requestTemplate() };

  const { data } = await axios.post(`${BACKEND_ENDPOINT()}`, req);

  log('req', req, 'response', data);

  handleUnauthorizedUser(data);

  if (!data || data.err) {
    // log(data.err);
    return;
  }
  return data;
}

export async function VOBReverifyUnableQuery(report: any) {
  const session: LoginState = getState().loginReducer;
  if (!session.isLoggedIn || !session.sessionData) {
    return;
  }

  let req = {
    API_NAME: 'VOBReverifyUnableQuery',
    API_AUTHENTICATION_TYPE: 'auth_session',
    API_AUTHENTICATION_TOKEN: session.sessionData.AuthToken,
    AUTHENTICATION_OWNER_ID: session.sessionData.CustomerId,
    API_PAYLOAD: {
      fastReportFlagName: "CL_FastReport_VOB_Reverify_Unable",
      customerId: session.sessionData.CustomerId,
      insuranceVerifiedValue: report.toLowerCase().includes('reverify') ? 'reverify' : 'unable',
    },
  };
  req = { ...req, ...requestTemplate() };

  const { data } = await axios.post(`${BACKEND_ENDPOINT()}`, req);

  log('req', req, 'response', data);

  handleUnauthorizedUser(data);

  if (!data || data.err) {
    // log(data.err);
    return;
  }
  return data;
}

export async function VOBNPAQuery() {
  const session: LoginState = getState().loginReducer;
  if (!session.isLoggedIn || !session.sessionData) {
    return;
  }

  let req = {
    API_NAME: 'VOBNPAQuery',
    API_AUTHENTICATION_TYPE: 'auth_session',
    API_AUTHENTICATION_TOKEN: session.sessionData.AuthToken,
    AUTHENTICATION_OWNER_ID: session.sessionData.CustomerId,
    API_PAYLOAD: {
      fastReportFlagName: "CL_FastReport_VOB_NPA",
      customerId: session.sessionData.CustomerId,
    },
  };
  req = { ...req, ...requestTemplate() };

  const { data } = await axios.post(`${BACKEND_ENDPOINT()}`, req);

  log('req', req, 'response', data);

  handleUnauthorizedUser(data);

  if (!data || data.err) {
    // log(data.err);
    return;
  }
  return data;
}

export async function OMDailyAuditQuery() {
  const session: LoginState = getState().loginReducer;
  if (!session.isLoggedIn || !session.sessionData) {
    return;
  }

  let req = {
    API_NAME: 'OMReportQuery',
    API_AUTHENTICATION_TYPE: 'auth_session',
    API_AUTHENTICATION_TOKEN: session.sessionData.AuthToken,
    AUTHENTICATION_OWNER_ID: session.sessionData.CustomerId,
    API_PAYLOAD: {
      fastReportFlagName: "CL_FastReport_OM",
      customerId: session.sessionData.CustomerId,
      reportType: "DailyAudit"
    },
  };
  req = { ...req, ...requestTemplate() };

  const { data } = await axios.post(`${BACKEND_ENDPOINT()}`, req);

  log('req', req, 'response', data);

  handleUnauthorizedUser(data);

  if (!data || data.err) {
    // log(data.err);
    return;
  }
  return data;
}

export async function OMNPAConfirmationCallsQuery() {
  const session: LoginState = getState().loginReducer;
  if (!session.isLoggedIn || !session.sessionData) {
    return;
  }

  let req = {
    API_NAME: 'OMReportQuery',
    API_AUTHENTICATION_TYPE: 'auth_session',
    API_AUTHENTICATION_TOKEN: session.sessionData.AuthToken,
    AUTHENTICATION_OWNER_ID: session.sessionData.CustomerId,
    API_PAYLOAD: {
      fastReportFlagName: "CL_FastReport_OM",
      customerId: session.sessionData.CustomerId,
      reportType: "Confirmation"
    },
  };
  req = { ...req, ...requestTemplate() };

  const { data } = await axios.post(`${BACKEND_ENDPOINT()}`, req);

  log('req', req, 'response', data);

  handleUnauthorizedUser(data);

  if (!data || data.err) {
    // log(data.err);
    return;
  }
  return data;
}

export async function OMPastQuery() {
  const session: LoginState = getState().loginReducer;
  if (!session.isLoggedIn || !session.sessionData) {
    return;
  }

  let req = {
    API_NAME: 'OMReportQuery',
    API_AUTHENTICATION_TYPE: 'auth_session',
    API_AUTHENTICATION_TOKEN: session.sessionData.AuthToken,
    AUTHENTICATION_OWNER_ID: session.sessionData.CustomerId,
    API_PAYLOAD: {
      fastReportFlagName: "CL_FastReport_OM",
      customerId: session.sessionData.CustomerId,
      reportType: "Past"
    },
  };
  req = { ...req, ...requestTemplate() };

  const { data } = await axios.post(`${BACKEND_ENDPOINT()}`, req);

  log('req', req, 'response', data);

  handleUnauthorizedUser(data);

  if (!data || data.err) {
    // log(data.err);
    return;
  }
  return data;
}

export async function OMVolumeQuery() {
  const session: LoginState = getState().loginReducer;
  if (!session.isLoggedIn || !session.sessionData) {
    return;
  }

  let req = {
    API_NAME: 'OMReportQuery',
    API_AUTHENTICATION_TYPE: 'auth_session',
    API_AUTHENTICATION_TOKEN: session.sessionData.AuthToken,
    AUTHENTICATION_OWNER_ID: session.sessionData.CustomerId,
    API_PAYLOAD: {
      fastReportFlagName: "CL_FastReport_OM",
      customerId: session.sessionData.CustomerId,
      reportType: "Volume"
    },
  };
  req = { ...req, ...requestTemplate() };

  const { data } = await axios.post(`${BACKEND_ENDPOINT()}`, req);

  log('req', req, 'response', data);

  handleUnauthorizedUser(data);

  if (!data || data.err) {
    // log(data.err);
    return;
  }
  return data;
}

export async function OMRestartQuery() {
  const session: LoginState = getState().loginReducer;
  if (!session.isLoggedIn || !session.sessionData) {
    return;
  }

  let req = {
    API_NAME: 'OMReportQuery',
    API_AUTHENTICATION_TYPE: 'auth_session',
    API_AUTHENTICATION_TOKEN: session.sessionData.AuthToken,
    AUTHENTICATION_OWNER_ID: session.sessionData.CustomerId,
    API_PAYLOAD: {
      fastReportFlagName: "CL_FastReport_OM",
      customerId: session.sessionData.CustomerId,
      reportType: "Restart"
    },
  };
  req = { ...req, ...requestTemplate() };

  const { data } = await axios.post(`${BACKEND_ENDPOINT()}`, req);

  log('req', req, 'response', data);

  handleUnauthorizedUser(data);

  if (!data || data.err) {
    // log(data.err);
    return;
  }
  return data;
}

export async function OMInCompleteMovementQuery() {
  const session: LoginState = getState().loginReducer;
  if (!session.isLoggedIn || !session.sessionData) {
    return;
  }

  let req = {
    API_NAME: 'OMReportQuery',
    API_AUTHENTICATION_TYPE: 'auth_session',
    API_AUTHENTICATION_TOKEN: session.sessionData.AuthToken,
    AUTHENTICATION_OWNER_ID: session.sessionData.CustomerId,
    API_PAYLOAD: {
      fastReportFlagName: "CL_FastReport_OM",
      customerId: session.sessionData.CustomerId,
      reportType: "IncompleteMovement"
    },
  };
  req = { ...req, ...requestTemplate() };

  const { data } = await axios.post(`${BACKEND_ENDPOINT()}`, req);

  log('req', req, 'response', data);

  handleUnauthorizedUser(data);

  if (!data || data.err) {
    // log(data.err);
    return;
  }
  return data;
}

export async function OMABNNeededQuery() {
  const session: LoginState = getState().loginReducer;
  if (!session.isLoggedIn || !session.sessionData) {
    return;
  }

  let req = {
    API_NAME: 'OMReportQuery',
    API_AUTHENTICATION_TYPE: 'auth_session',
    API_AUTHENTICATION_TOKEN: session.sessionData.AuthToken,
    AUTHENTICATION_OWNER_ID: session.sessionData.CustomerId,
    API_PAYLOAD: {
      fastReportFlagName: "CL_FastReport_OM",
      customerId: session.sessionData.CustomerId,
      reportType: "ABNNeeded"
    },
  };
  req = { ...req, ...requestTemplate() };

  const { data } = await axios.post(`${BACKEND_ENDPOINT()}`, req);

  log('req', req, 'response', data);

  handleUnauthorizedUser(data);

  if (!data || data.err) {
    // log(data.err);
    return;
  }
  return data;
}

export async function OMActiveWithoutNFVQuery() {
  const session: LoginState = getState().loginReducer;
  if (!session.isLoggedIn || !session.sessionData) {
    return;
  }

  let req = {
    API_NAME: 'OMReportQuery',
    API_AUTHENTICATION_TYPE: 'auth_session',
    API_AUTHENTICATION_TOKEN: session.sessionData.AuthToken,
    AUTHENTICATION_OWNER_ID: session.sessionData.CustomerId,
    API_PAYLOAD: {
      fastReportFlagName: "CL_FastReport_OM",
      customerId: session.sessionData.CustomerId,
      reportType: "ActWONxtFlwUp"
    },
  };
  req = { ...req, ...requestTemplate() };

  const { data } = await axios.post(`${BACKEND_ENDPOINT()}`, req);

  log('req', req, 'response', data);

  handleUnauthorizedUser(data);

  if (!data || data.err) {
    // log(data.err);
    return;
  }
  return data;
}

export async function OMNPAFaxedQuery() {
  const session: LoginState = getState().loginReducer;
  if (!session.isLoggedIn || !session.sessionData) {
    return;
  }

  let req = {
    API_NAME: 'OMReportQuery',
    API_AUTHENTICATION_TYPE: 'auth_session',
    API_AUTHENTICATION_TOKEN: session.sessionData.AuthToken,
    AUTHENTICATION_OWNER_ID: session.sessionData.CustomerId,
    API_PAYLOAD: {
      fastReportFlagName: "CL_FastReport_OM",
      customerId: session.sessionData.CustomerId,
      reportType: "NPAFaxed"
    },
  };
  req = { ...req, ...requestTemplate() };

  const { data } = await axios.post(`${BACKEND_ENDPOINT()}`, req);

  log('req', req, 'response', data);

  handleUnauthorizedUser(data);

  if (!data || data.err) {
    // log(data.err);
    return;
  }
  return data;
}

export async function OMUnableToVerifyQuery() {
  const session: LoginState = getState().loginReducer;
  if (!session.isLoggedIn || !session.sessionData) {
    return;
  }

  let req = {
    API_NAME: 'OMReportQuery',
    API_AUTHENTICATION_TYPE: 'auth_session',
    API_AUTHENTICATION_TOKEN: session.sessionData.AuthToken,
    AUTHENTICATION_OWNER_ID: session.sessionData.CustomerId,
    API_PAYLOAD: {
      fastReportFlagName: "CL_FastReport_OM",
      customerId: session.sessionData.CustomerId,
      reportType: "UnblVerifyLeadIns"
    },
  };
  req = { ...req, ...requestTemplate() };

  const { data } = await axios.post(`${BACKEND_ENDPOINT()}`, req);

  log('req', req, 'response', data);

  handleUnauthorizedUser(data);

  if (!data || data.err) {
    // log(data.err);
    return;
  }
  return data;
}

export async function NPAIntakeFormReportQuery() {
  const session: LoginState = getState().loginReducer;
  if (!session.isLoggedIn || !session.sessionData) {
    return;
  }

  let req = {
    API_NAME: 'NPAIntakeFormReportQuery',
    API_AUTHENTICATION_TYPE: 'auth_session',
    API_AUTHENTICATION_TOKEN: session.sessionData.AuthToken,
    AUTHENTICATION_OWNER_ID: session.sessionData.CustomerId,
    API_PAYLOAD: {
      fastReportFlagName: "CL_FastReport_NPAIntakeForm",
      customerId: session.sessionData.CustomerId,
    },
  };
  req = { ...req, ...requestTemplate() };

  const { data } = await axios.post(`${BACKEND_ENDPOINT()}`, req);

  log('req', req, 'response', data);

  handleUnauthorizedUser(data);

  if (!data || data.err) {
    // log(data.err);
    return;
  }
  return data;
}

export async function patientNavigatorQuery(fetchedForDays: any, startDate: any, endDate: any, duplicateReferralDate: boolean) {
  const session: LoginState = getState().loginReducer;
  if (!session.isLoggedIn || !session.sessionData) {
    return;
  }

  let url = window.location.href.toLowerCase();
  if (url.includes('patientnavigator2') && (!startDate || !endDate)) {
    log('Return patNavQue', startDate, endDate)
    return;
  }

  let req = {
    API_NAME: 'patientNavigatorQuery',
    API_AUTHENTICATION_TYPE: 'auth_session',
    API_AUTHENTICATION_TOKEN: session.sessionData.AuthToken,
    AUTHENTICATION_OWNER_ID: session.sessionData.CustomerId,
    API_PAYLOAD: {
      fastReportFlagName: "CL_FastReport_PatientNavigator",
      customerId: session.sessionData.CustomerId,
      forPastDays: fetchedForDays,
      onlyHavingDuplicateRefDate: duplicateReferralDate,
      //PN1 uses forPastDays. PN2 uses startDate,endDate,
      reportType: 2,
      startDate: startDate,
      endDate: endDate,
    },
  };
  req = { ...req, ...requestTemplate() };

  const { data } = await axios.post(`${BACKEND_ENDPOINT()}`, req);

  log('req', req, 'response', data);

  handleUnauthorizedUser(data);

  if (!data || data.err) {
    // log(data.err);
    return;
  }
  return data;
}

export async function getDropDownValues(par: any, reportName: any) {
  const session: LoginState = getState().loginReducer;
  if (!session.isLoggedIn || !session.sessionData) {
    return;
  }

  let req = {
    API_NAME: 'getdropdownvalues',
    API_AUTHENTICATION_TYPE: 'auth_session',
    API_AUTHENTICATION_TOKEN: session.sessionData.AuthToken,
    AUTHENTICATION_OWNER_ID: session.sessionData.CustomerId,
    API_PAYLOAD: {
      AliasQuestionText: par,
      fastReportFlagName: retFlagName(reportName),
      customerId: session.sessionData.CustomerId,
    },
  };
  req = { ...req, ...requestTemplate() };

  const { data } = await axios.post(`${BACKEND_ENDPOINT()}`, req);
  // log('req', req, 'response', data);
  handleUnauthorizedUser(data);

  if (!data || data.err) {
    // log(data.err);
    return;
  }
  return data;
}

export async function getChildOrgs() {
  const session: LoginState = getState().loginReducer;
  if (!session.isLoggedIn || !session.sessionData) {
    return;
  }

  let req = {
    API_NAME: 'getchildorgs',
    API_AUTHENTICATION_TYPE: 'auth_session',
    API_AUTHENTICATION_TOKEN: session.sessionData.AuthToken,
    AUTHENTICATION_OWNER_ID: session.sessionData.CustomerId,
    API_PAYLOAD: {
      ORGID: 141,
      fastReportFlagName: "CL_FastReport_PatientNavigator",
      customerId: session.sessionData.CustomerId,
    },
  };
  req = { ...req, ...requestTemplate() };

  const { data } = await axios.post(`${BACKEND_ENDPOINT()}`, req);
  // log('req', req, 'response', data);
  handleUnauthorizedUser(data);

  if (!data || data.err) {
    // log(data.err);
    return;
  }
  return data;
}