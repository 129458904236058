import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { log } from '../../../utils/util';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';

export function BasicDatePicker(props: any) {
  // log(props);

  const [start, setStart] = React.useState(props.props.value[0] || props.props.resetValue[0]);
  const [end, setEnd] = React.useState(props.props.value[1] || props.props.resetValue[1]);

  let val: any;

  useEffect(() => {
    addListener();
    val = [start, end];
  }, [props.props]);

  function addListener() {
    document.getElementById(props.props.name + '.start.id')?.addEventListener('blur', (event) => {
      // log('blur');
      onBlur();
    })

    document.getElementById(props.props.name + '.end.id')?.addEventListener('blur', (event) => {
      // log('blur');
      onBlur();
    })
  }

  function onChangeStart(params: any) {
    let djs = dayjs(params).format('YYYY-MM-DD')
    // log(params, djs);
    if (djs.includes('Invalid')) {
      return;
    }
    setStart(djs);
    onTrail(djs, end);
  }

  function onChangeEnd(params: any) {
    let djs = dayjs(params).format('YYYY-MM-DD')
    // log(params, djs);
    if (djs.includes('Invalid')) {
      return;
    }
    setEnd(djs);
    onTrail(start, djs);
  }

  function onTrail(start: any, end: any) {
    // log('trail', start, end);
    if (start.length == 0 || end.length == 0) return;
    if (dayjs(start) > dayjs(end)) {
      props.props.toast.warn('Starting date is after ending date. Please correct!', { toastId: 'st', pauseOnHover: true })
      return;
    }

    val = [start, end];
    props.props.setValue(val);
    props.props.handle(val, props.props);
  }

  function onBlur() {
    // props.props.setValue(val);
    // props.props.handle(val, props.props);
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div id={props.props.name + '.cont.id'} style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <DesktopDatePicker
          label="Start (yyyy-mm-dd)"
          inputFormat="YYYY-MM-DD"
          value={start}
          onChange={onChangeStart}
          renderInput={(params) => {
            params.id = props.props.name + '.start.id';
            return <TextField {...params} />
          }}
        />
        <div style={{ width: '20px' }}></div>
        <DesktopDatePicker
          label="End (yyyy-mm-dd)"
          inputFormat="YYYY-MM-DD"
          value={end}
          onChange={onChangeEnd}
          renderInput={(params) => {
            params.id = props.props.name + '.end.id';
            return <TextField {...params} />
          }}
        />
      </div>
    </LocalizationProvider>
  );
}
