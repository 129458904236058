let isAutoRefreshSet = false;

let skip = ['accountlookup', 'patientnavigator2'];

export function autoRefresh(setRefreshBtnDisable: any, fetchOrganizations: any) {
  if (isAutoRefreshSet) return;
  isAutoRefreshSet = true;

  let time = parseInt(process.env.REACT_APP_PATIENT_NAVIGATOR_REPORT_REFRESH_TIMER + '') * 1000 ||
    300 * 1000;
  console.log('autoRefresh set', time);

  setInterval(() => {
    execRefresh(setRefreshBtnDisable, fetchOrganizations);
  }, time);
}

function skipThis() {
  let url = window.location.href.toLowerCase();

  for (let i = 0; i < skip.length; i++) {
    if (url.includes(skip[i])) {
      // console.log('skipping', skip[i]);
      return true;
    }
  }
  return false;
}

export async function clickOnRefresh(setRefreshBtnDisable: any, fetchOrganizations: any) {
  //refreshing
  setRefreshBtnDisable(true);
  await fetchOrganizations(false);
  //refreshing is done
  setRefreshBtnDisable(false);
}

async function execRefresh(setRefreshBtnDisable: any, fetchOrganizations: any) {
  if (skipThis()) return;

  clickOnRefresh(setRefreshBtnDisable, fetchOrganizations);
}